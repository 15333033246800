/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
const HumanResource = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/human-resource/",
        name:
          "Free Electronic Signature Solutions for HR Department | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/human-resource/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Manage employee esignature related agreements faster, better and efficiently with Document eSign solutions for HR Department.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/human-resource/"],
          },
        ],
      },
    ],
  }

  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Free Electronic Signature Solutions for HR Department | Document eSign"
          description="Manage employee esignature related agreements faster, better and efficiently with Document eSign solutions for HR Department."
          schemaMarkup={schema}
        />
        <div className="intro hr-intro bg-blue-light ani-in show-on-scroll">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-4 py-lg-5 ani-top">
                <h1 className="pt-1">
                  Electronic signature solutions for HR department workflow
                </h1>
                <p className="mt-4 pt-1 fw-regular">
                  Accelerate HR productivity with Document eSign and get rid of
                  the hassles of paperwork by collecting data and multiple
                  signatures digitally.
                  <br />
                  <br />
                  Document eSign solution lets you connect every step of the
                  employee lifecycle, right from hiring to retiring. Retrieve
                  every stored and signed HR forms, NDAs, and contracts from the
                  cloud solution.
                </p>
                <a
                  href="https://app.documentesign.com/auth/signup"
                  target="_blank"
                  className="btn btn-dark mt-4 mb-2 px-md-5"
                >
                  Get started
                </a>
                <br />
                <small className="text-muted">
                  <strong>Free forever,</strong> No credit card required
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lights">
          <div className="container py-5 mt-lg-4">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    src={withPrefix("images/document-preparation.jpg")}
                    className="card-img-top "
                    alt="document preparation"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-file-contract"></i>
                    </div>
                    <h4>Faster document preparation with reusable templates</h4>
                    <p className="card-text">
                      Our{" "}
                      <Link to="/free-pdf-esign-online/">
                        free electronic signature service{" "}
                      </Link>{" "}
                      lets you create an unlimited number of templates for your
                      HR forms that can be used any number of times as needed.
                      Personalize each of the pre-approved templates separately
                      as per employee’s needs and send them out to be signed
                      accordingly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/workflow-automation.jpg")}
                    className="card-img-top lazyload"
                    alt="workflow automation"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-cogs"></i>
                    </div>
                    <h4>Workflow automation</h4>
                    <p className="card-text">
                      Streamline the recruiting process collect eSignatures by
                      multiple job applicants from any device. Get your
                      contracts in custom signing order, improve compliance, and
                      keep track of real-time employee signature. Automate
                      workflow by delivering the documents like offer letters to
                      applicants in the right order for the esignature.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/organize-HR-forms.jpg")}
                    className="card-img-top lazyload"
                    alt="organize HR forms"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-file-invoice"></i>
                    </div>
                    <h4>Organize HR forms</h4>
                    <p className="card-text">
                      Organize important HR forms at one place in a single
                      online platform to avoid misplacing them. Customize your
                      documents in categories and efficiently manage your staff
                      records. Impress the potential candidates by combining
                      company and job information into an organized professional
                      way that’s easy to review.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/faster-approvals.jpg")}
                    className="card-img-top lazyload"
                    alt="faster approvals"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-clipboard-check"></i>
                    </div>
                    <h4>Faster approvals</h4>
                    <p className="card-text">
                      Get approvals to fill forms faster for quick and easy
                      streamlining of candidates and employees to sign and
                      return forms from any device. Smooth HR workflow ensures
                      recruiting good talent, involving leave and expense
                      management, streamlining organizational processes,
                      payroll, and other employee-related processes and
                      improving employee productivity.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/protect-ensitive-data.jpg")}
                    className="card-img-top lazyload"
                    alt="protect ensitive data"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-lock"></i>
                    </div>
                    <h4>Protect any sensitive Data</h4>
                    <p className="card-text">
                      Settle for two factor verification in your signing process
                      to keep NDAs, contracts and other HR forms secure. Before
                      signing a document, signers are required to enter a code
                      sent to them via SMS in their registered mobile number.
                      Protect your documents by adding passwords to restrict
                      copying, printing or editing any personal information.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col mb-4 ani-in show-on-scroll">
                <div className="card border-0 shadow-sm h-100">
                  <img
                    data-src={withPrefix("images/advanced-technology.jpg")}
                    className="card-img-top lazyload"
                    alt="advanced technology"
                  />
                  <div className="card-body position-relative">
                    <div className="icon-32">
                      <i className="fas fa-key"></i>
                    </div>
                    <h4>
                      Advanced technology to ensure accuracy and compliance
                    </h4>
                    <p className="card-text">
                      Document security and integrity can be attained by
                      advanced encryption. Document eSign invalidates any
                      changes in the document that are attempted between
                      signers. We offer a comprehensive{" "}
                      <Link to="/document-auditing/">audit trail </Link> to
                      track any changes made to the signed documents to stay
                      compliant with important processes, laws, and audits as
                      well as drift legal disputes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HumanResource
